import React from 'react';

const InterviewCard = ({ item }) => {
    const bgimg = item.backgroundNumber;
    let itemSlug = item.url != null ? item.url : item.slug;
    let divStyle;
    let itemPhotoSlug = item.hero.personaImage;
    let itemName = item.hero.name;
    let itemLocation = item.hero.location;
    let itemTitle = item.role;
    let itemClassName= 'wef-interview slds-p-horizontal--large slds-p-vertical--small slds-text-align_center slds-small-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-3';
    const interviewsUrl = '/cybersecurity/interviews/' + itemSlug;

    if (item.hasOwnProperty('favoriteBadges')) {
        itemClassName = 'wef-interview slds-align_absolute-center slds-p-around--small slds-text-align_center';
    } 
    divStyle = {
            backgroundImage: 'url(https://developer.salesforce.com/resources2/wef/' + itemPhotoSlug + '), url(https://developer.salesforce.com/resources2/wef/cards-background/0' + bgimg + '.png)',
        }
    return (
        <div className={itemClassName} key={Math.random() + Math.random()}>
            <a href={interviewsUrl}>   
                <div className="wef-interview-image slds-text-align_center slds-align_absolute-center" style={divStyle} ></div>
                <div className="wef-interview_name slds-p-top_medium">{itemName}</div>
                <div className="wef-interview_titleLoc">{itemTitle}</div>
                <div className="wef-interview_titleLoc">
                    <span className="slds-icon_container null slds-icon__svg--default" title="Description of icon when needed">
                        <svg className="slds-icon slds-icon-text-default" viewBox="-5 -2 36 36" id="checkin" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 .9C7.2.9 3.2 4.8 3.2 9.7c0 6.1 6.3 11.7 8.2 13.2.4.3.8.3 1.2 0 1.9-1.5 8.2-7.1 8.2-13.2 0-4.9-4-8.8-8.8-8.8zm0 12.5c-2 0-3.7-1.7-3.7-3.7S10 6 12 6s3.7 1.7 3.7 3.7-1.7 3.7-3.7 3.7z"/>
                        </svg>
                    </span>
                    {itemLocation}
                </div>
            </a>
        </div>
    )
}

export default InterviewCard;
