import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

// import iframeListener from '../utils/iframeListener';
import '../assets/stylesheets/index.scss';

export const siteQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        title
        keywords
        description
        tdsPath
        tmPath
      }
    }
  }
`;

function OnReady(currentItem) {
  const isBrowser = typeof window !== "undefined";
  if(isBrowser) {
    window.jQuery = window.$ = jQuery;
    var bread = currentItem.pathContext.data
    $(document).ready(function () { 
      // Handler for .ready() called.
      // Render Breadcrumb
      const tdsBreadcrumbs = document.querySelectorAll('tds-breadcrumbs');
      customElements.whenDefined("tds-breadcrumbs").then(() => {
        tdsBreadcrumbs.forEach((element, id) => {
          (bread.breadcrumb_trailmix_url != "")?
            element.items = [
              {href: `/cybersecurity`, label: 'Cybersecurity Career Path', overflow: false, width: 1},   
              {href: bread.breadcrumb_trailmix_url, label: 'Trailmix' , overflow: false, width: 1},
              {href: ``, label: bread.breadcrumb_title, overflow: false, width: 1}
            ]
          :
            element.items = [
              {href: `/cybersecurity`, label: 'Cybersecurity Career Path', overflow: false, width: 1},   
              {href: ``, label: bread.breadcrumb_title, overflow: false, width: 1}
            ];
        });
      });
    });
  }
}

const Layout = ({ children, currentItem }) => {
  return (
    <StaticQuery
      query={siteQuery}
      render={({ site: { siteMetadata } }) => (
        <>
          {/* prettier-ignore */}
          <Helmet defaultTitle={siteMetadata.title}>
            <meta name="description" content={siteMetadata.description} />
            <meta name="keywords" content={siteMetadata.keywords} />
            <meta name="site_name" content={siteMetadata.title} className="swiftype" data-type="string" />
            <link rel="stylesheet" media="all" href="https://developer.salesforce.com/resources2/fonts/Nexa/Nexa.css"/>
            
            <script src={`${siteMetadata.tdsPath}/modules/tds.js`} type="module"></script>
            <script src={`${siteMetadata.tmPath}/modules/tm.js`} type="module"></script>
            <link href={`${siteMetadata.tdsPath}/css/tds-theme-provider.css`} rel="stylesheet" />
            <link href={`${siteMetadata.tdsPath}/css/tds.css`} rel="stylesheet" />

            {
              // <link rel="stylesheet" media="all" href="https://unpkg.com/@salesforce-ux/design-system@2.9.2/assets/styles/salesforce-lightning-design-system.min.css"/>
            }
            <meta property="og:title" content={siteMetadata.title} />

          </Helmet>
          <div id="wef-site" className="wef-site">
            {children}
            {OnReady(currentItem)}
          </div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
