import React from 'react';

const FunFacts = ({questionsAnswers}) => {
    let qandaElements = [];
    const totalItems = questionsAnswers.length;
    let cardClass = totalItems == 4 ? 'wef-site_fun-facts_card-smaller' : 'wef-site_fun-facts_card-bigger'; 
    questionsAnswers.map((item, index) => {
        const iconStyle={
            backgroundImage : 'url(https://developer.salesforce.com/resources2/wef/' + item.icon + ')' 
        };
        if(item.question.length > 0 && item.answer.length > 0){
            qandaElements.push(
                <div key={index} className={`slds-card slds-col slds-large-size_1-of-4 slds-medium-size_1-of-2 slds-small-size_1-of-1 wef-site_fun-facts_card ${cardClass}`}>
                    <div className="slds-card wef-site_fun-facts_icon" style={iconStyle}>

                    </div>
                    <div className="wef-site_fun-facts_question">
                        {item.question}
                    </div>
                    <div className="wef-site_fun-facts_answer"
                         dangerouslySetInnerHTML={{ __html: item.answer }}>
                    </div>
                </div>
            );
        }
    });
    if(qandaElements.length > 0){
        return (
            <div className="wef-site_fun-facts slds-align_absolute-center">
                <div className="slds-container--xx-large slds-p-top_xx-large slds-p-horizontal_medium">
                <div className="wef-section_title slds-size-1-of-1">
                Fun Facts
                </div>
                    <div className="slds-grid slds-wrap slds-grid_align-center slds-grid--vertical-stretch">
                        {qandaElements}
                    </div>
                    
                </div>
            </div>
        );
    }
    else{
        return (
            <div></div>
        );
    }
};

export default FunFacts;