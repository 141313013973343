import React from 'react';

const LearnSkills = ({questionsAnswers, q_aQuote, q_aQuoteCustomPosition}) => {
    let qandaElements = [];
    let quotePosition = q_aQuoteCustomPosition =! null && q_aQuoteCustomPosition != undefined ? q_aQuoteCustomPosition : 2;
    questionsAnswers.map((item, index) => {
        if(index == quotePosition && q_aQuote != ''){
            qandaElements.push(
                <div key={index + Math.random()} className="slds-p-bottom_xx-large wef-site_quote slds-align_absolute-center slds-container_medium JC(L)">
                    <span>“</span>{q_aQuote}
                </div>
            );
        }
        qandaElements.push(
            
            <div className="slds-p-bottom_xx-large W(100%)" key={index}>
                <div className="slds-grid slds-gutters_medium slds-wrap ">
                    <div className="slds-col slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_1-of-3 slds-p-horizontal_medium wef-site_question slds-order_1 slds-p-bottom_medium">
                        {item.question}
                    </div>
                    <div className="slds-col slds-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_2-of-3 slds-p-horizontal_medium wef-site_answer slds-order_2"
                    dangerouslySetInnerHTML={{ __html: item.answer }}>
                        
                    </div>
                </div>
            </div>
            
        );
    });
    return (
        <div className="slds-container--x-large slds-p-horizontal_large slds-p-top_xx-large wef-qa-section slds-container--center">
            <div className="slds-grid slds-wrap ">
                {qandaElements}
            </div>
            
        </div>
    );
};

export default LearnSkills;
