import React, { useState, useEffect } from 'react';
import InterviewCard from './InterviewCard';
import { StaticQuery, graphql } from 'gatsby';

const interviewsData = graphql`
  query interviewsData {
    allInterviewsDataJson {
      edges {
        node {
          hero {
            backgroundImage
            location
            personaImage
            name
            quoteText
            title
          }
          role
          slug
          backgroundNumber
        }
      }
    }
  }
`;

const interviewQuery = () => (
    <StaticQuery
      query={interviewsData}
      render={ data => {
        let interviewData = [];
        data.allInterviewsDataJson.edges.forEach((edge) => {
          const item = edge.node;
          if( item !== null ){
              interviewData.push(item);
          }
        });
        return (
          <InterviewsCards interviewData={interviewData}/>
        )
      }}
    />
  );
  const InterviewsCards = ({interviewData}) => {
    
    let interviewElements = [];
    let amountToAdd = 6;
    let actualItemRole;
    interviewData.map((item, index) => {
      let k = `interview-card-${index}`;
      if (typeof window !== 'undefined') {
          if (!window.location.href.includes(item.slug)) {
            interviewElements.push(
                <InterviewCard
                  key={k}
                  item={item}
                />
              )
          } else {
            actualItemRole = item.role;
          }
      } 
    });
    //Ordering the array so it shows first interviews for the same role
    interviewElements.sort((a,b) => {
        if (a.props.item.role === actualItemRole) {
            return -1;
        }
        if (b.props.item.role === actualItemRole) {
          return 1;
        }
        return 0;
      });

return (
        <div className="slds-container--x-large slds-container--center slds-p-horizontal_xx-large">
            <div className="slds-grid slds-wrap slds-grid_align-center slds-grid_pull-padded-large slds-m-top-large slds-grid_vertical-stretch">
                {interviewElements}
            </div>
        </div>
);  
};

export default interviewQuery;
