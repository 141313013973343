import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../layouts/Layout';
import PropTypes from 'prop-types';
import Partners from '../components/Partners';
import InterviewCards from '../components/InterviewCards';
import QandA from '../components/QandA';
import FunFacts from '../components/FunFacts';


export const InterviewTemplate = item => {
    const {
        pageContext: {
            data:{
                slug,
                role,
                hero,
                description,
                q_aQuote,
                q_aQuoteCustomPosition,
                questionsAnswers,
                funFacts,
                favoriteBadges
            }
        },
    } = item;

    const heroStyle={
        backgroundImage : 'url(https://developer.salesforce.com/resources2/wef/' + hero.personaImage + ')' 
    };

    const quoteStyle = hero.quoteText != null && hero.quoteText.length <= 80 ? 'wef-hero_title-short' : '';

    return (
        <Layout currentItem={item}>
            <div className="slds-container--center wef-site">
            <div>
				<tds-breadcrumbs></tds-breadcrumbs>
        	</div>
            {/* Interview page Banner */}
            <div className="wef-hero-interviews custom-align--vertical-center">
                <div className="slds-container--x-large slds-container--center slds-p-horizontal_large H(100%)">
                    <div className="slds-grid slds-grid_vertical-align-end H(100%) wef-hero-interviews-grid">
                        <div className="slds-large-size-2-of-12 slds-medium-size-1-of-4 slds-small-size-1-of-1 wef-interview-hero-col">
                            <div className="wef-interview-image" style={heroStyle}></div>
                        </div>
                        <div className="slds-large-size-10-of-12 slds-medium-size-3-of-4 slds-small-size-1-of-1 wef-interview-hero-detail-col">
                            <div className="slds-grid slds-wrap wef-hero-details-grid">
                                <div className={`wef-hero_title slds-size--1-of-1 ${quoteStyle}`}>
                                    <span className="wef-hero-quote">“</span>
                                    <span>{hero.quoteText}</span>
                                </div>
                                <div className="wef-hero-details slds-size--1-of-1 slds-align-bottom">
                                    <div>
                                        <div className="wef-hero-name">{hero.name}</div>
                                        <div className="wef-hero-info">{hero.title}</div>
                                        <div className="wef-hero-info">
                                            <span className="slds-icon_container slds-icon__svg--default" title="Description of icon when needed">
                                                <svg className="slds-icon slds-icon-text-default" viewBox="0 -4 36 36" id="checkin" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 .9C7.2.9 3.2 4.8 3.2 9.7c0 6.1 6.3 11.7 8.2 13.2.4.3.8.3 1.2 0 1.9-1.5 8.2-7.1 8.2-13.2 0-4.9-4-8.8-8.8-8.8zm0 12.5c-2 0-3.7-1.7-3.7-3.7S10 6 12 6s3.7 1.7 3.7 3.7-1.7 3.7-3.7 3.7z"/>
                                                </svg>
                                            </span>
                                            {hero.location}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Description quote */}
            <div className="Lh(36) Fz(24) slds-p-around_x-large slds-text-align--center slds-container--center slds-container--large">
                {description}
            </div>
            <hr className="wef-site-hr slds-align_absolute-center"/>

                {/* Q&A */}
                    <QandA
                        questionsAnswers = {questionsAnswers}
                        q_aQuote = {q_aQuote}
                        q_aQuoteCustomPosition = {q_aQuoteCustomPosition}
                    />
                {/* FUN FACTS             */}
                    <FunFacts
                        questionsAnswers = {funFacts}
                    />
                {/* MORE INTERVIEWS */}
                {
                    <div className="wef-section">
                        <div className="wef-section_title">
                        Meet more security professionals
                        </div>
                        <InterviewCards
                            Interview= {item}
                        />
                    </div>
                }

                {/* PARTNERS */}
                <Partners />
            </div>
        </Layout>
    );
};

export default InterviewTemplate;
